import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { enviroment } from '../../environments/environment';
import { CriteriaResponse, ValidityResponse } from '../interfaces/ApiResponse.interface';
import { IFeedback } from '../interfaces/ApiRequests.interface';
@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService {
  private api_url:string = enviroment.patient_assessment_experience_api
  
  constructor(private httpClient:HttpClient) { }
  linkValidity(client_id:string,uuid:string){
    console.log('API URL',this.api_url)
    const url = `${this.api_url}/feedback/check-link-validity`
    return this.httpClient.get<ValidityResponse>(url,{
      params:{
        uuid
      },
      headers:{
        db:client_id
      }
    })
  }
  getCriteria(client_id:string,uuid:string){
    const url = `${this.api_url}/feedback/${client_id}/${uuid}`
    return this.httpClient.get<CriteriaResponse>(url,{
      headers:{
        db:client_id
      }
    })
  }
  submitFeedBack(client_id:string,data:IFeedback){
    const url = `${this.api_url}/feedback/submit-feedback`
    return this.httpClient.post(url,data,{
      headers:{
        db:client_id
      }
    })
  }
  getClientLogo(client_id:string,uuid:string){
    const url = `${this.api_url}/feedback/logo/${uuid}`
    return this.httpClient.get(url,{
      headers:{
        db:client_id
      }
    })
  }
  
}
